import React from 'react'
import { MobileScreen, Logo, FeatureScreen } from '../components';
import './styles/landing.scss';
const Leftdash = require('../assets/leftdash.png');
const Rightdash = require('../assets/rightdash.png');
const fulllogo = require('../assets/logo.png');
const insta = require('../assets/insta.png');
const twitter = require('../assets/twitter.png'); 
const facebook = require('../assets/facebook.png');
const download = require('../assets/download.png');
const cloud = require('../assets/cloud.png');
const support = require('../assets/customer-service.png');
const exchange = require('../assets/exchange.png'); 
const international = require('../assets/international.png');


export default function Landing() {

  return (
    <div className="landing-container">
      <header>
        <div className="nav-bar">
          <div className="logo">
            <Logo />
            {/* <img src={fulllogo} alt="_" /> */}
          </div>
          <div className="links">
            <button className="link">Learn More</button>
            <button className="link button">Download now</button>
          </div>
        </div>
        <div className="banner">
          <div className="banner-title">
            <h1 className="title">Global finance <span>simplified</span> </h1>
            <p className="sub-title">Fast, east and secure</p>
            <button className="link button">Download Now</button>
          </div>
          <div className="mobile-view">
            <MobileScreen />
          </div>
        </div>
      </header>
      <div className="key-features">
        <div className="hook card">
          <div className="content left">
            <img src={Leftdash} className="hookline" alt="_" />
            <div>
              <img src={cloud} className="box" alt="i" />
            </div>
            <div className="c-title">Crypto and FX</div>
            <div className="c-details">
              Trade Cyrpo and Forex on RemFex. All combined with our efficient finance engine
            </div>
          </div>
        </div>
        <div className="screen">
          <h2 className="title">Key Features</h2>
          <FeatureScreen />
        </div>
        <div className="hook card">
          <div className="content right">
            <img src={Rightdash} className="hookline" alt="_" />
            <div>
              <img src={exchange} className="box" alt="i" />
            </div>
            <div className="c-title">Multi Currency Support</div>
            <div className="c-details"> 
              RemFex supports multiple currencies, and allows for international remittance
            </div>
          </div>
        </div>
      </div>
      <div className="many-more"> 
        <h2 className="title hide-sm">And many more...</h2>
        <h2 className="title hide-lg">Key Features</h2> 
        <div className="cards">
          <div className="card">
            <div>
              <img src={exchange} className="box" alt="i" />
            </div>
            <div className="c-title">Multi Currency Support</div>
            <div className="c-details">
              RemFex supports multiple currencies, and allows for international remittance
            </div>
          </div>
          <div className="card">
            <div>
              <img src={international} className="box" alt="i" />
            </div>
            <div className="c-title">Global Payouts</div>  
            <div className="c-details">
              We provide the best rates and efficiently allow for transfers internationally
            </div>
          </div> 
          <div className="card">
            <div>
              <img src={support} className="box" alt="i" />
            </div> 
            <div className="c-title">24/7 Support</div>
            <div className="c-details">
              You are guaranteed no downtime, as our support staffs are available to help anytime.
            </div> 
          </div>
        </div>
      </div>
      <div className="download">
        <div className="instruction">
          <h2 className="title">Send money to multiple countries in <span>3 easy steps</span></h2>
          <button className="button secondary">Download now</button>
        </div>
        <div className="transaction">
          <img src={download} alt = "download" />
          {/* <Transaction /> */}
        </div>
      </div>
      <footer className="footer">
        <div className="container">
          <div className="logo">
            <img src={fulllogo} alt="_" />
            <span>© {new Date().getFullYear()} RemFex | All Rights Reserved</span>
          </div>
          <div className="contact">
            <h2 className="title">Get in touch</h2>
            <div className="socials">
              <a href="https://instagram.com/remfexllc?utm_medium=copy_link">
                <img src={insta} alt="i" />
              </a>

              <a href="https://twitter.com/RemfexLLC">
                <img src={twitter} alt="i" />
              </a>

              <a href="https://facebook.com/RemfexLLC">
                <img src={facebook} alt="i" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div >
  )
}
