import * as React from 'react';
const download = require('../assets/logo.png');

function Logo(props) {
  return (
    <img alt = "logo" height = "32" src={download}/>
    // <svg
    //   width={35}
    //   height={32}
    //   viewBox="0 0 35 32"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M24.863 0c-3.148 0-6.739 1.223-10.086 3.527 2.055-.822 4.096-1.248 5.983-1.248 2.573 0 4.86.79 6.515 2.446 2.952 2.951 3.156 7.918 1.023 12.92 1.59-1.387 2.994-2.482 4.05-3.157 1.883-4.71 1.616-9.335-1.165-12.116C29.578.767 27.36 0 24.863 0z"
    //     fill="#6EB075"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M14.777 3.528C11.997 4.64 9.19 6.479 6.695 8.974c-6.856 6.857-8.758 16.07-4.25 20.58C4.102 31.209 6.39 32 8.963 32c2.536 0 5.349-.77 8.104-2.238.341-.531.786-1.148 1.32-1.832-1.708.593-3.392.898-4.966.898-2.496 0-4.714-.767-6.32-2.372-4.374-4.374-2.528-13.31 4.122-19.962a25.49 25.49 0 013.555-2.966z"
    //     fill="#FEDD6A"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M20.76 2.279c-1.888 0-3.928.425-5.983 1.248a25.432 25.432 0 00-3.555 2.967c-6.65 6.65-8.496 15.588-4.122 19.962 1.605 1.605 3.824 2.372 6.32 2.372 1.574 0 3.257-.305 4.966-.898 1.398-1.79 3.404-4.035 5.738-6.37a85.524 85.524 0 014.173-3.915c2.134-5.002 1.93-9.969-1.022-12.92-1.655-1.656-3.943-2.446-6.516-2.446z"
    //     fill="#D6C73D"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M34.036 13.725c-.336 0-.92.27-1.688.763-1.079 2.698-2.864 5.424-5.286 7.846a24.929 24.929 0 01-4.996 3.884 25.035 25.035 0 01-5 3.544c-.656 1.021-.926 1.72-.696 1.95.054.055.135.082.24.082 1.173 0 5.374-3.298 9.912-7.836 4.945-4.945 8.416-9.49 7.754-10.151-.054-.055-.135-.082-.24-.082z"
    //     fill="#363434"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M32.348 14.487c-1.056.676-2.46 1.77-4.05 3.158-1.126 2.64-2.904 5.29-5.272 7.66-.316.314-.636.619-.96.913a24.908 24.908 0 004.995-3.884c2.423-2.423 4.208-5.149 5.287-7.847z"
    //     fill="#243028"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M22.066 26.218a20.79 20.79 0 01-3.68 1.712 25.14 25.14 0 00-1.32 1.832 25.022 25.022 0 005-3.544z"
    //     fill="#373226"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M28.298 17.646a85.267 85.267 0 00-4.173 3.915c-2.335 2.334-4.34 4.58-5.739 6.37a20.814 20.814 0 003.68-1.713c.324-.294.644-.599.96-.914 2.368-2.368 4.146-5.018 5.272-7.658z"
    //     fill="#313122"
    //   />
    // </svg>
  )
}

export default Logo